<template>
  <valorem-modal
    id="modal-status-agendamento"
    ref="modal"
    :titulo-modal="
      $t('SOLICITACOES.STATUS_AGENDAMENTO.TITULO_MODAL_VISUALIZAR')
    "
    titulo-ok="..."
    ok-desabilitado
  >
    <div class="row">
      <input-text
        class="col-12 col-md-1"
        :label="$t('SOLICITACOES.STATUS_AGENDAMENTO.STATUS')"
        v-model="dados.status"
        disabled
      />
      <input-text
        class="col-12 col-md-5"
        :label="$t('SOLICITACOES.STATUS_AGENDAMENTO.SID')"
        v-model="dados.sid"
        disabled
      />
      <input-text
        class="col-12 col-md-2"
        :label="$t('SOLICITACOES.STATUS_AGENDAMENTO.USN')"
        v-model="dados.schedule_usn"
        disabled
      />
      <input-date
        class="col-12 col-md-2"
        :label="$t('SOLICITACOES.STATUS_AGENDAMENTO.INICIO')"
        v-model="dados.initial_date"
        disabled
      />
      <input-text
        class="col-12 col-md-1"
        :label="$t('SOLICITACOES.STATUS_AGENDAMENTO.PARCELAS')"
        v-model="dados.number_of_times"
        disabled
      />
      <input-text
        class="col-12 col-md-1"
        :label="$t('SOLICITACOES.STATUS_AGENDAMENTO.AMOUNT')"
        v-model="amount"
        disabled
      />
    </div>
  </valorem-modal>
</template>
  <script>
import { InputText, InputDate } from "@/components/inputs";
import helpers from "../../../common/utils/helpers";

export default {
  components: {
    InputText,
    InputDate,
  },
  data() {
    return {
      dados: {},
      amount: 0,
    };
  },
  methods: {
    abrirModal: function (id, dados) {
      this.amount = helpers.formatarValor(parseFloat(dados.amount) / 100);

      this.dados = dados;
      this.$refs.modal.abrirModal();
    },
    fecharModal: function () {
      this.$refs.modal.fecharModal();
    },
  },
  computed: {},
};
</script>
  <style lang=""></style>
  