import ApiService from '@/common/api/api.service'
import Store from "../../../store/Store";

const AssinaturaService = {
  listar: function (filtro, paginacao = 1, porPagina = 10) {
    let link = `assinatura?NumeroDaPagina=${paginacao}&TamanhoDaPagina=${porPagina}`;

    let estabecimentoSelecionado = Store.getters['estabelecimentoSelecionado'];
    link = link.concat(`${estabecimentoSelecionado ? `&EstabelecimentoId=${estabecimentoSelecionado}` : ''}`);
    link = link.concat(`${filtro.termo ? `&TermoPesquisa=${filtro.termo}` : ''}`);
    link = link.concat(`${filtro.dataInicial ? `&DataInicial=${filtro.dataInicial}` : ''}`);
    link = link.concat(`${filtro.dataFinal ? `&DataFinal=${filtro.dataFinal}` : ''}`);
    link = link.concat(`${filtro.somentePendente ? `&SomentePendente=true` : ''}`);
    link = link.concat(`${filtro.somenteAntecipacao ? `&SomenteAntecipacao=true` : ''}`);
    link = link.concat(`${filtro.situacao ? `&Situacao=${filtro.situacao}` : ''}`);

    return ApiService.get(link);
  },
  salvar: function (form) {
    if (form.id)
      return ApiService.put('assinatura', form)
    return ApiService.post('assinatura', form)
  },
  alterarCartao: function (form) {
    return ApiService.put('assinatura/atualizar-cartao', form)
  },
  buscar: function (id) {
    return ApiService.get(`assinatura/${id}`)
  },
  solicitarCancelamento: function (form) {
    return ApiService.put('assinatura/solicitar-cancelamento', form)
  },
  responderSolicitacaoCancelamento: function (form) {
    return ApiService.put('assinatura/responder-solicitacao-cancelamento', form)
  },
  buscarSolicitacao: function (id) {
    return ApiService.get(`assinatura/solicitacao-pendente/${id}`)
  },
  buscarSolicitacoes: function (assinaturaId = null, cobrancaId = null) {
    let link = `assinatura/solicitacoes?1=1`;

    link = link.concat(`${assinaturaId ? `&AssinaturaId=${assinaturaId}` : ''}`);
    link = link.concat(`${cobrancaId ? `&CobrancaId=${cobrancaId}` : ''}`);
    return ApiService.get(link)
  },
  solicitarAntecipacao: function (form) {
    return ApiService.put('assinatura/antecipar', form)
  },
  buscarFavorecidos: function () {
    let estabecimentoSelecionado = Store.getters['estabelecimentoSelecionado'];
    return ApiService.get(`favorecido/${estabecimentoSelecionado}`)
  },
  recomprar: function (form) {
    return ApiService.put('assinatura/recomprar-assinatura', form)
  },
  buscarStatusAgendamento: function (id) {
    return ApiService.get(`assinatura/consulta-status-agendamento/${id}`)
  },
}

export default AssinaturaService
