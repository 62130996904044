<template>
  <valorem-modal
    ref="modal"
    :titulo-modal="
      $t(
        desabilitado
          ? 'SOLICITACOES.CANCELAMENTO_ASSINATURA.TITULO_MODAL_VISUALIZAR'
          : form.solicitacaoId != null
          ? `SOLICITACOES.CANCELAMENTO_ASSINATURA.${
              form.aprovar ? 'APROVAR' : 'REJEITAR'
            }.TITULO_MODAL`
          : 'SOLICITACOES.CANCELAMENTO_ASSINATURA.TITULO_MODAL'
      )
    "
    @ok="salvar"
    :titulo-ok="
      desabilitado
        ? '...'
        : $t(
            form.solicitacaoId != null
              ? `SOLICITACOES.CANCELAMENTO_ASSINATURA.${
                  form.aprovar ? 'APROVAR' : 'REJEITAR'
                }.BOTAO_CANCELAMENTO`
              : 'SOLICITACOES.CANCELAMENTO_ASSINATURA.BOTAO_SOLICITAR_CANCELAMENTO'
          )
    "
    :ok-desabilitado="desabilitado"
  >
    <div class="row">
      <input-text-area
        id="input-motivo"
        :disabled="form.solicitacaoId != null || desabilitado"
        class="col-12"
        :label="$t('SOLICITACOES.MOTIVO_CANCELAMENTO')"
        v-model="form.motivoCancelamento"
        ref="form-motivo"
        required
      />
      <input-text-area
        id="input-resposta"
        :disabled="desabilitado"
        v-if="form.solicitacaoId != null"
        class="col-12"
        :label="$t('SOLICITACOES.RESPOSTA_VALOREM')"
        v-model="form.respostaValorem"
        ref="form-motivo"
        required
      />
    </div>
  </valorem-modal>
</template>
<script>
import { InputTextArea } from "@/components/inputs";
import AssinaturasService from "@/common/services/assinaturas/assinaturas.service";
import { START_LOADING, STOP_LOADING } from "@/store/Store";

export default {
  props: {
    listagem: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    InputTextArea,
  },
  data() {
    return {
      opcoes: {
        cancelamento: [],
      },
      form: {},
      desabilitado: false,
    };
  },
  methods: {
    abrirModalDesabilitado: function (assinaturaId, solicitacao) {
      this.desabilitado = true;
      this.abrirModal(assinaturaId, solicitacao);
    },
    abrirModal: function (assinaturaId, solicitacao = null, aprovar = false) {
      this.form = {
        assinaturaId: assinaturaId,
        motivoCancelamento: "",
      };
      if (solicitacao) {
        this.form.motivoCancelamento = solicitacao.motivo;
        this.form.solicitacaoId = solicitacao.id;
        this.form.respostaValorem = solicitacao.respostaValorem;
        this.form.aprovar = aprovar;
      }
      this.$refs.modal.abrirModal();
    },
    fecharModal: function () {
      this.$refs.modal.fecharModal();
    },
    salvar: function () {
      if (!this.validarFormulario("form-")) return;

      this.confirmar(
        this.$t(
          this.form.solicitacaoId != null
            ? `SOLICITACOES.CANCELAMENTO_ASSINATURA.${
                this.form.aprovar ? "APROVAR" : "REJEITAR"
              }.CONFIRMAR_CANCELAMENTO`
            : "SOLICITACOES.CANCELAMENTO_ASSINATURA.TITULO_CONFIRMAR_PEDIDO_CANCELAMENTO"
        ),
        this.$t("GERAL.MENSAGEM_CONFIRMAR_ACAO")
      ).then((confirmado) => {
        if (!confirmado) return;

        this.$store.dispatch(START_LOADING);
        let api =
          this.form.solicitacaoId != null
            ? AssinaturasService.responderSolicitacaoCancelamento
            : AssinaturasService.solicitarCancelamento;
        api(this.form)
          .then(() => {
            this.alertaSucesso(
              this.$t(
                this.form.solicitacaoId != null
                  ? `SOLICITACOES.CANCELAMENTO_ASSINATURA.${
                      this.form.aprovar ? "APROVAR" : "REJEITAR"
                    }.SUCESSO`
                  : "SOLICITACOES.CANCELAMENTO_ASSINATURA.SUCESSO"
              )
            ).then(() => {
              if (!this.listagem) {
                this.$router.push({ name: "assinaturas" });
              } else {
                this.$emit("fechar");
              }
            });
          })
          .finally(() => {
            this.$store.dispatch(STOP_LOADING);
          });
      });
    },
  },
};
</script>
<style lang=""></style>
