<template>
  <valorem-modal
    ref="modal"
    @ok="salvar"
    :titulo-modal="$t('ASSINATURAS.ANTECIPACAO.TITULO_MODAL')"
    :titulo-ok="$t('ASSINATURAS.EFETUAR_ANTECIPACAO')"
    :ok-desabilitado="desabilitado"
  >
    <div class="row">
      <div class="col-12">
        <input-text
          id="input-filtro-favorecido"
          no-label
          :placeholder="$t('ASSINATURAS.FILTRO_FAVORECIDO')"
          v-model="filtro"
        >
        </input-text>
      </div>
      <div class="col-12">
        <valorem-tabela
          id="tabela-favorecido"
          sem-selecionar-todos
          sem-paginacao
          selecionar-somente-um
          v-model="dadosFiltrados"
          :colunas="tabela.colunas"
          ref="tabela-favorecido"
        >
        </valorem-tabela>
      </div>
    </div>
  </valorem-modal>
</template>
<script>
import AssinaturasService from "@/common/services/assinaturas/assinaturas.service";
import { START_LOADING, STOP_LOADING } from "@/store/Store";
import { InputText } from "../../../components/inputs";
export default {
  props: {
    listagem: {
      type: Boolean,
      default: false,
    },
  },
  components: { InputText },
  data() {
    return {
      filtro: "",
      tabela: {
        colunas: [
          { key: "seletor", label: "#" },
          { key: "codigo", label: "Cód." },
          { key: "nome", label: "Nome" },
          { key: "banco", label: "Banco" },
          { key: "agConta", label: "Ag/Conta" },
          { key: "pix", label: "PIX" },
          { key: "indicador", label: "Tit." },
        ],
        dados: [],
      },
      form: {
        assinaturaIds: [],
        favorecido: {
          codigo: null,
          indicador: null,
          agencia: null,
          agenciaDigito: null,
          conta: null,
          contaDigito: null,
        },
      },
    };
  },
  methods: {
    abrirModal: function (assinaturaIds) {
      this.form.assinaturaIds = assinaturaIds;
      this.$refs.modal.abrirModal();
      this.buscarFavorecidos();
    },
    buscarFavorecidos: function () {
      this.$store.dispatch(START_LOADING);
      AssinaturasService.buscarFavorecidos()
        .then((res) => {
          this.tabela.dados = res.data.data.favorecidos;
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    fecharModal: function () {
      this.$refs.modal.fecharModal();
    },
    salvar: function () {
      this.confirmar(
        this.$t(`ASSINATURAS.ANTECIPAR_ASSINATURA`),
        this.$t("GERAL.MENSAGEM_CONFIRMAR_ACAO")
      ).then((confirmado) => {
        if (!confirmado) return;
        let selecionado = this.tabela.dados.find((el) => el.selecionado);

        this.form.favorecido.codigo = selecionado.codigo;
        this.form.favorecido.indicador = selecionado.indicador;
        this.form.favorecido.agencia = selecionado.agencia;
        this.form.favorecido.agenciaDigito = selecionado.digitoAgencia;
        this.form.favorecido.conta = selecionado.conta.split("-")[0];
        this.form.favorecido.contaDigito =
          selecionado.conta.indexOf("-") >= 0
            ? selecionado.conta.split("-")[1]
            : null;

        this.$store.dispatch(START_LOADING);
        AssinaturasService.solicitarAntecipacao(this.form)
          .then((res) => {
            let mensagem =
              res.data.informations.length > 0
                ? this.$t("ASSINATURAS.ANTECIPADO_SUCESSO_AVISO")
                : this.$t("ASSINATURAS.ANTECIPADO_SUCESSO");
            res.data.informations.forEach((element) => {
              mensagem = `<hr>${mensagem}<br>${element}`;
            });
            this.alertaSucesso(mensagem, true).then(() => {
              this.fecharModal();
              this.$emit("listar");
            });
          })
          .finally(() => {
            this.$store.dispatch(STOP_LOADING);
          });
      });
    },
  },
  computed: {
    dadosFiltrados: {
      set: function (valor) {
        this.tabela.dados = valor;
      },
      get: function () {
        return this.tabela.dados.filter((el) => {
          return el.nome.includes(this.filtro.toLowerCase());
        });
      },
    },
    desabilitado: function () {
      return !this.tabela.dados.find((el) => el.selecionado);
    },
  },
};
</script>
<style lang=""></style>
