<template>
  <div>
    <b-card>
      <div>
        <h4 class="card-title">{{ tabela.quantidadeItens }} registros</h4>
      </div>
      <div
        class="filtro-valorem d-flex align-items-end justify-content-between mb-2"
      >
        <div class="d-flex align-items-center w-100">
          <input-text
            id="input-filtro-termo"
            no-label
            :placeholder="$t('ASSINATURAS.FILTRO')"
            v-model="filtro.termo"
            class="flex-fill mr-2"
          />
          <input-date
            id="input-filtro-data-inicial"
            no-label
            class="mr-2"
            v-model="filtro.dataInicial"
          />
          <h6 class="mr-2 mt-1">a</h6>
          <input-date
            id="input-filtro-data-final"
            no-label
            v-model="filtro.dataFinal"
          />
          &nbsp; &nbsp;
          <input-select
            id="input-filtro-situacao"
            no-label
            class="mr-2"
            v-model="filtro.situacao"
            :firstItemLabel="$t('ASSINATURAS.SITUACAO')"
            :options="optionsSituacao"
          />

          <div class="d-flex">
            <b-button class="ml-2" variant="outline-secondary" @click="limpar">
              <i class="fas fa-filter"></i>
              <i class="fas fa-times sub-i"></i>
            </b-button>
            <b-button
              class="ml-2"
              variant="outline-valorem-ciano"
              @click="filtrar"
            >
              <div class="d-flex align-items-center">
                <i class="fas fa-search mr-2"></i>
                <span>Filtrar</span>
              </div>
            </b-button>
          </div>
        </div>
      </div>
      <div class="flex-responsive my-3">
        <div class="d-flex flex-column mr-5">
          <small class="font-weight-bold text-valorem-grafite">
            {{ $t("ASSINATURAS.LIMITE_CREDITO_ANTECIPACAO") }}
          </small>
          <h5 class="text-valorem-ciano">
            {{ valorLimiteDisponivelAntecipacao }}
          </h5>
        </div>
        <div class="d-flex flex-column mr-5">
          <small class="font-weight-bold text-valorem-grafite">
            {{ $t("ASSINATURAS.TOTAL_ASSINATURAS_SELECIONADAS_ANTECIPACAO") }}
          </small>
          <h5 class="text-valorem-dourado">
            {{ valorSelecionadoAntecipacao }}
          </h5>
        </div>
        <div class="d-flex flex-column mr-5">
          <small class="font-weight-bold text-valorem-grafite">
            {{ $t("ASSINATURAS.TOTAL_DISPONIVEIS_ANTECIPACAO") }}
          </small>
          <h5 class="text-valorem-azul-naval">
            {{ valorTotalDisponivelAntecipacao }}
          </h5>
        </div>
        <div class="d-flex flex-column justify-content-end flex-fill">
          <b-button
            id="btn-visualizar-pendentes-cancelamento"
            v-if="ehAdmin"
            variant="outline-valorem-dourado"
            @click="filtrarPendentes"
            class="mb-1"
          >
            <div class="d-flex align-items-center">
              <i
                :class="`far fa-${
                  filtro.somentePendente ? 'check-' : ''
                }square mr-2`"
              ></i>
              <small>Visualizar pendentes de cancelamento</small>
            </div>
          </b-button>
          <b-button
            id="btn-visualizar-disponivel-antecipacao"
            v-if="!ehOperador"
            variant="outline-valorem-azul-naval"
            @click="filtrarDisponiveisAntecipacao"
          >
            <div class="d-flex align-items-center">
              <i
                :class="`far fa-${
                  filtro.somenteAntecipacao ? 'check-' : ''
                }square mr-2`"
              ></i>
              <small>{{
                $t("ASSINATURAS.VISUALIZAR_SOMENTE_DISPONIVEIS_ANTECIPACAO")
              }}</small>
            </div>
          </b-button>
        </div>
      </div>

      <div class="d-flex align-items-center mb-2">
        <b-button
          id="btn-visualizar"
          @click="editar(null)"
          v-if="dadosSelecionados.length == 1"
          class="mr-4"
          variant="outline-primary"
        >
          {{ $t("GERAL.VISUALIZAR") }}
        </b-button>

        <b-button
          id="btn-aprovar-cancelamento"
          @click="abrirModalCancelamento(true)"
          v-if="podeCancelar"
          variant="outline-valorem-ciano"
          class="mr-2"
        >
          <div class="d-flex align-items-center">
            <i class="fas fa-thumbs-up mr-2"></i>
            Aprovar cancelamento
          </div>
        </b-button>
        <b-button
          id="btn-rejeitar-cancelamento"
          @click="abrirModalCancelamento(false)"
          v-if="podeCancelar"
          variant="outline-valorem-vermelho"
          class="mr-4"
        >
          <div class="d-flex align-items-center">
            <i class="fas fa-thumbs-down mr-2"></i>
            Rejeitar cancelamento
          </div>
        </b-button>

        <b-button
          id="btn-efetuar-antecipacao"
          @click="antecipar(null)"
          v-if="podeAntecipar"
          class="mr-2"
          variant="primary"
        >
          {{ $t("ASSINATURAS.EFETUAR_ANTECIPACAO") }}
        </b-button>

        <b-button
          id="btn-recomprar"
          @click="recomprar(null)"
          v-if="podeRecomprar"
          class="mr-2"
          variant="valorem-ciano"
        >
          {{ $t("ASSINATURAS.EFETUAR_RECOMPRA") }}
        </b-button>

        <b-button
          id="btn-status"
          @click="abrirModalStatusAgendamento(null)"
          v-if="ehAdmin && dadosSelecionados.length == 1"
          class="mr-4"
          variant="outline-primary"
        >
          {{ $t("GERAL.STATUS") }}
        </b-button>

        <b-alert
          class="py-2 mt-3"
          :show="!estabelecimentoPodeAntecipar.podeAntecipar"
          variant="danger"
        >
          {{ estabelecimentoPodeAntecipar.mensagem }}
        </b-alert>
        <modal-status-agendamento ref="status-agendamento" />
      </div>
      <valorem-tabela
        id="tabela"
        sem-selecionar-todos
        :metodo-validacao-selecionar="metodoValidacaoSelecionar"
        v-model="tabela.dados"
        :colunas="tabela.colunas"
        :quantidade-itens="tabela.quantidadeItens"
        ref="tabela"
        backend-paginado
        @paginar="listar"
      >
        <template #cell(situacao)="dados">
          <span
            :class="`text-${dados.value?.variante} font-weight-bold text-capitalize`"
          >
            {{ dados.value?.text }}
          </span>
        </template>
        <template #cell(situacaoAntecipacao)="dados">
          <span :class="`text-capitalize`">
            {{ dados.value?.text }}
          </span>
        </template>
      </valorem-tabela>
      <modal-cancelamento
        ref="modal"
        @fechar="fecharModalCancelamento"
        listagem
      />
      <modal-antecipacao
        ref="modal-antecipacao"
        @fechar="fecharModalAntecipacao"
      />
    </b-card>
  </div>
</template>
<script>
import { START_LOADING, STOP_LOADING } from "@/store/Store";
import { DEFINIR_BREADCRUMB } from "@/store/breadcrumbs.module";
import { InputText, InputDate, InputSelect } from "../../components/inputs";
import helpers from "../../common/utils/helpers";
import ModalCancelamento from "./components/ModalCancelamentoAssinatura";
import ModalAntecipacao from "./components/ModalAntecipacaoAssinatura";
import ModalStatusAgendamento from "./components/ModalStatusAgendamento.vue";
import AssinaturasService from "@/common/services/assinaturas/assinaturas.service";
export default {
  components: {
    InputText,
    InputDate,
    InputSelect,
    ModalCancelamento,
    ModalAntecipacao,
    ModalStatusAgendamento,
  },
  data: function () {
    return {
      filtro: {
        termo: "",
        dataInicial: "",
        dataFinal: "",
        situacao: null,
        somentePendente: false,
        somenteAntecipacao: false,
      },
      optionsSituacao: [
        { value: "Ativa", text: "Ativa" },
        { value: "Cancelada", text: "Cancelada" },
        { value: "EmAtraso", text: "Em Atraso" },
        { value: "PendenteCancelamento", text: "Pendente Cancelamento" },
      ],
      obj: null,
      tabela: {
        dados: [],
        colunas: [
          { key: "seletor", label: "" },
          { key: "codigo", label: "Código" },
          { key: "nome", label: this.$t("ASSINATURAS.TABELA.NOME") },
          {
            key: "valor",
            label: this.$t("ASSINATURAS.TABELA.VALOR"),
            formatter: (v) => {
              if (v) return helpers.formatarValor(v);
            },
          },
          {
            key: "valorTotalPlano",
            label: this.$t("ASSINATURAS.TABELA.VALOR_TOTAL"),
            formatter: (v) => {
              if (v) return helpers.formatarValor(v);
            },
          },
          {
            key: "situacao",
            label: this.$t("ASSINATURAS.TABELA.SITUACAO"),
            formatter: (v) => {
              if (v) return helpers.getEnum(v);
            },
          },
          {
            key: "nomeAssinante",
            label: this.$t("ASSINATURAS.TABELA.NOME_CLIENTE"),
          },
          {
            key: "frequencia",
            label: this.$t("ASSINATURAS.TABELA.FREQUENCIA"),
          },
          {
            key: "quantidadeCobranca",
            label: this.$t("ASSINATURAS.TABELA.QTD_COBRANCA"),
          },
          {
            key: "proximaCobranca",
            label: this.$t("ASSINATURAS.TABELA.PROXIMA_COBRANCA"),
            formatter: (v) => {
              if (v) return helpers.formatarDataBr(v);
            },
          },
          {
            key: "limiteCreditoDisponivel",
            label: this.$t("ASSINATURAS.TABELA.VALOR_ANTECIPACAO"),
            formatter: (v) => {
              return helpers.formatarValor(v);
            },
          },
          {
            key: "situacaoAntecipacao",
            label: this.$t("ASSINATURAS.TABELA.ANTECIPACAO"),
            formatter: (v) => {
              if (v) return helpers.getEnum(v);
            },
          },
          // {
          //   key: "SituacaoAssinatura",
          //   label: this.$t("ASSINATURAS.SITUACAO"),
          //   formatter: (v) => {
          //     if (v) return helpers.getEnum(v);
          //   },
          // },
        ],
        quantidadeItens: 0,
        paginaAtual: 0,
        porPagina: 0,
      },
      botaoAtivarInativar: null,
      limiteDisponivelAntecipacao: 0,
      totalDisponivelAntecipacao: 0,
      estabelecimentoPodeAntecipar: false,
    };
  },
  mounted() {
    this.$store.dispatch(DEFINIR_BREADCRUMB, [
      { titulo: this.$t("ASSINATURAS.TITULO_LISTA") },
    ]);
    this.listar();
    //this.buscarAntecipacao();
  },
  methods: {
    filtrarPendentes: function () {
      this.filtro.somenteAntecipacao = false;
      this.filtro.somentePendente = !this.filtro.somentePendente;
      this.filtrar();
    },
    filtrarDisponiveisAntecipacao: function () {
      this.filtro.somentePendente = false;
      this.filtro.somenteAntecipacao = !this.filtro.somenteAntecipacao;
      this.filtrar();
    },
    filtrar: function () {
      this.$refs.tabela.limpar();
      this.listar();
    },
    limpar: function () {
      this.filtro = {
        termo: "",
        dataInicial: "",
        dataFinal: "",
        situacao: null,
        somentePendente: this.filtro.somentePendente,
        somenteAntecipacao: this.filtro.somenteAntecipacao,
      };
      this.filtrar();
    },
    listar: function (paginaAtual = 0, porPagina = 10) {
      this.$store.dispatch(START_LOADING);
      AssinaturasService.listar(this.filtro, paginaAtual, porPagina)
        .then((res) => {
          this.limiteDisponivelAntecipacao =
            res.data.data.limiteCreditoAntecipacao;
          this.totalDisponivelAntecipacao = res.data.data.valorDisponivel;
          if (this.$store.getters["estabelecimentoSelecionado"]) {
            this.estabelecimentoPodeAntecipar =
              res.data.data.estabelecimentoPodeAntecipar;
          } else {
            this.estabelecimentoPodeAntecipar = {
              podeAntecipar: false,
              mensagem:
                "Não é possivel antecipar com a opção `Todas empresas` selecionada",
            };
          }

          this.tabela.dados = res.data.data.itens;
          this.tabela.paginaAtual = paginaAtual;
          this.tabela.porPagina = porPagina;
          this.tabela.quantidadeItens =
            res.data.data.paginacao.totalDeElementos;
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    editar: function (id) {
      if (!id) {
        this.$router.push({
          name: "assinaturas-editar",
          params: { id: this.dadosSelecionados[0].id },
        });
      } else {
        this.$router.push({ name: "assinaturas-editar", params: { id: id } });
      }
    },
    abrirModalCancelamento: function (aprovar) {
      this.$store.dispatch(START_LOADING);
      AssinaturasService.buscarSolicitacao(this.dadosSelecionados[0].id)
        .then((res) => {
          this.$refs.modal.abrirModal(
            this.dadosSelecionados[0].id,
            res.data.data,
            aprovar
          );
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    fecharModalCancelamento: function () {
      this.$refs.modal.fecharModal();
      this.listar(this.tabela.paginaAtual, this.tabela.porPagina);
    },
    metodoValidacaoSelecionar: function (item) {
      if (this.botaoAtivarInativar == null) return true;
      if (item.ativo != this.botaoAtivarInativar) return false;
      return true;
    },
    antecipar: function () {
      if (
        this.limiteDisponivelAntecipacao <
        helpers.removerValor(this.valorSelecionadoAntecipacao)
      ) {
        this.toastErro(this.$t("ASSINATURAS.VALOR_EXCEDE_LIMITE_ANTECIPACAO"));
        return;
      }
      this.$refs["modal-antecipacao"].abrirModal(
        this.dadosSelecionados.map((el) => el.id)
      );
    },
    fecharModalAntecipacao: function () {
      this.$refs["modal-antecipacao"].fecharModal();
      this.listar(this.tabela.paginaAtual, this.tabela.porPagina);
    },
    recomprar: function () {
      this.confirmar(
        this.$t(`ASSINATURAS.TITULO_RECOMPRAR`),
        this.$t("GERAL.MENSAGEM_CONFIRMAR_ACAO")
      ).then((confirmado) => {
        if (!confirmado) return;
        this.$store.dispatch(START_LOADING);
        AssinaturasService.recomprar({
          assinaturaId: this.dadosSelecionados[0].id,
        })
          .then(() => {
            this.alertaSucesso(
              this.$t("ASSINATURAS.MENSAGEM_SUCESSO_RECOMPRA")
            ).then(() => {
              this.listar();
            });
          })
          .finally(() => {
            this.$store.dispatch(START_LOADING);
          });
      });
    },
    abrirModalStatusAgendamento: function () {
      this.$store.dispatch(START_LOADING);
      AssinaturasService.buscarStatusAgendamento(this.dadosSelecionados[0].id)
        .then((res) => {
          var schedule = res.data.data.schedule;
          this.$refs["status-agendamento"].abrirModal(
            this.dadosSelecionados[0].id,
            schedule
          );
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    fecharModalStatusAgendamento: function () {
      this.$refs["status-agendamento"].fecharModal();
      this.listar(this.tabela.paginaAtual, this.tabela.porPagina);
    },
  },
  computed: {
    dadosSelecionados: function () {
      return this.tabela.dados.filter((el) => {
        return el.selecionado;
      });
    },
    permissao: function () {
      return helpers.validarAcesso("Assinatura");
    },
    ehAdmin: function () {
      return this.$store.getters.ehAdmin;
    },
    podeCancelar: function () {
      if (this.dadosSelecionados.length != 1) return false;

      return (
        this.dadosSelecionados[0].situacao == "PendenteCancelamento" &&
        this.ehAdmin
      );
    },
    podeRecomprar: function () {
      if (this.dadosSelecionados.length != 1) return false;

      return (
        this.dadosSelecionados[0].situacao == "EmAtraso" &&
        this.dadosSelecionados[0].situacaoAntecipacao == "Antecipada" &&
        this.ehAdmin
      );
    },
    estabelecimentoSelecionado: function () {
      return this.$store.getters["estabelecimentoSelecionado"];
    },
    podeAntecipar: function () {
      if (
        this.dadosSelecionados.length == 0 ||
        !this.permissao.editar ||
        !this.estabelecimentoPodeAntecipar.podeAntecipar
      )
        return false;

      return this.dadosSelecionados.every(
        (el) =>
          el.liberarAntecipacaoRecorrencia && el.limiteCreditoDisponivel > 0
      );
    },
    valorLimiteDisponivelAntecipacao: function () {
      return helpers.formatarValor(this.limiteDisponivelAntecipacao);
    },
    valorTotalDisponivelAntecipacao: function () {
      return helpers.formatarValor(this.totalDisponivelAntecipacao);
    },
    valorSelecionadoAntecipacao: function () {
      return helpers.formatarValor(
        this.dadosSelecionados
          .filter((el) => el.liberarAntecipacaoRecorrencia)
          .reduce(
            (total, assinatura) => total + assinatura.limiteCreditoDisponivel,
            0
          )
      );
    },
  },
  watch: {
    dadosSelecionados: function () {
      if (this.dadosSelecionados.length == 1) {
        this.botaoAtivarInativar = this.dadosSelecionados[0].ativo;
      } else if (this.dadosSelecionados.length == 0) {
        this.botaoAtivarInativar = null;
      }
    },
    estabelecimentoSelecionado: function () {
      this.listar();
    },
  },
};
</script>
<style lang=""></style>
