var render = function render(){var _vm=this,_c=_vm._self._c;return _c('valorem-modal',{ref:"modal",attrs:{"titulo-modal":_vm.$t(
      _vm.desabilitado
        ? 'SOLICITACOES.CANCELAMENTO_ASSINATURA.TITULO_MODAL_VISUALIZAR'
        : _vm.form.solicitacaoId != null
        ? `SOLICITACOES.CANCELAMENTO_ASSINATURA.${
            _vm.form.aprovar ? 'APROVAR' : 'REJEITAR'
          }.TITULO_MODAL`
        : 'SOLICITACOES.CANCELAMENTO_ASSINATURA.TITULO_MODAL'
    ),"titulo-ok":_vm.desabilitado
      ? '...'
      : _vm.$t(
          _vm.form.solicitacaoId != null
            ? `SOLICITACOES.CANCELAMENTO_ASSINATURA.${
                _vm.form.aprovar ? 'APROVAR' : 'REJEITAR'
              }.BOTAO_CANCELAMENTO`
            : 'SOLICITACOES.CANCELAMENTO_ASSINATURA.BOTAO_SOLICITAR_CANCELAMENTO'
        ),"ok-desabilitado":_vm.desabilitado},on:{"ok":_vm.salvar}},[_c('div',{staticClass:"row"},[_c('input-text-area',{ref:"form-motivo",staticClass:"col-12",attrs:{"id":"input-motivo","disabled":_vm.form.solicitacaoId != null || _vm.desabilitado,"label":_vm.$t('SOLICITACOES.MOTIVO_CANCELAMENTO'),"required":""},model:{value:(_vm.form.motivoCancelamento),callback:function ($$v) {_vm.$set(_vm.form, "motivoCancelamento", $$v)},expression:"form.motivoCancelamento"}}),(_vm.form.solicitacaoId != null)?_c('input-text-area',{ref:"form-motivo",staticClass:"col-12",attrs:{"id":"input-resposta","disabled":_vm.desabilitado,"label":_vm.$t('SOLICITACOES.RESPOSTA_VALOREM'),"required":""},model:{value:(_vm.form.respostaValorem),callback:function ($$v) {_vm.$set(_vm.form, "respostaValorem", $$v)},expression:"form.respostaValorem"}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }